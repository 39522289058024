import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import PlanInfoComponent from 'src/containers/AutomaticPlan/components/Plan/PlanInfo'
import { PlanInterface } from 'src/interfaces/plan'
import { getFeedbackDetail } from 'src/useCase/feedback'
import { getInfo } from 'src/useCase/user'
import { PLAN_TYPE } from 'src/constants/app'
import { EMAIL_SUPPORT } from 'src/constants/env-constant'
import MenuNameComponent from 'src/components/common/MenuNameComponent'

const ProposalContainer: React.FC = (props: any) => {
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const paramsUrl = useParams()
  const [activePlan, setActivePlan] = useState<PlanInterface>()
  const feedbackDetail = useAppSelector(
    (state) => state.domain.feedbacks.feedbackDetail.entity,
  )
  const user = useAppSelector((state) => state.ui.app.authInfo)

  useEffect(() => {
    dispatch(
      getFeedbackDetail({
        feedback_id: Number(paramsUrl?.id),
        with: 'plans,doctor',
      }),
    )
  }, [])

  useEffect(() => {
    if (user?.state_transitions !== undefined) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,userTreatments',
        }),
      )
    }
  }, [])

  const selectPlan = (plan) => {
    if (plan) {
      setActivePlan(plan)
    }
  }

  // const goToDetail = (id: number) => {
  //   LocalStorage.setActivePlan(id)
  //   LocalStorage.setPlanType(PLAN_TYPE.PRESCRIPTION)
  //   navigate(`/plans/${id}`)
  // }
  // const handleSubmit = () => {
  //   if (activePlan) {
  //     LocalStorage.setActivePlan(activePlan.id)
  //     LocalStorage.setPlanType(PLAN_TYPE.PRESCRIPTION)
  //     // navigate(PAYMENT_PATH.PAYMENT_CONFIRM)

  //     if (activePlan) {
  //       dispatch(
  //         orderPlan({
  //           plan_id: activePlan.id,
  //           plan_type: PLAN_TYPE.PRESCRIPTION,
  //           redirect_path: PAYMENT_PATH.PAYMENT_INFO,
  //           doctor_feedback_id: Number(paramsUrl?.id),
  //         }),
  //       )
  //     }
  //   }
  // }

  if (!feedbackDetail) {
    return <div></div>
  }

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto proposal">
        <StepsHeader
          step={10}
          stepActive={4}
          stepActiveName={'処方のご提案'}
          className="top-70"
        />
        <ProposalDiv className="proposal">
          <div className="proposal-content">
            <HeaderStyle>処方提案</HeaderStyle>
            <MenuNameComponent />
            <div className="proposal__content">
              <p>{feedbackDetail.doctor?.name}です。</p>
              <br />
              <p>問診内容を踏まえて、以下の処方プランをご提案いたします。</p>

              {/* <br />
              <p>
                AGA治療は効果が出るまでに、少なくても3~6ヶ月かかるため、診察の手間を最小限に抑えて治療を継続しやすい定期便プランにてお届けいたします。
              </p> */}
            </div>
          </div>
          <div className="proposal__content-badge">
            <div className="proposal__content-badge-header">
              <p className="proposal__content-badge-title">
                ご提案の処方プラン
              </p>
              <p></p>
              <p>以下の処方プランからお選びください</p>
            </div>
            <PlanInfoComponent
              plans={feedbackDetail.plans}
              activePlan={activePlan}
              selectPlan={selectPlan}
              planTypeBefore={PLAN_TYPE.PRESCRIPTION}
              feedbackId={Number(paramsUrl?.id)}
            />
            <div className="pt-0 p-20">
              <p>定期便は定期の期間が終了した後に再問診が必要となります。</p>
              <p>
                お体に合わない等、ご不明な点がございましたらメールにてカスタマーサポートへご相談ください。
              </p>
              <p>
                <a href={`mailto:${EMAIL_SUPPORT}`} className="reset-link">
                  {EMAIL_SUPPORT}
                </a>
              </p>
            </div>
          </div>
          {/* <div className="proposal-content mt-4">
            <p>定期便は12ヶ月毎に再問診が必要となります。</p>
            <p>
              お体に合わない等、ご不明な点がございましたらチャットにてカスタマーサポートへご相談ください。
            </p>

            {activePlan && activePlan.id && (
              <div className="proposal__content-noti">
                <p className="proposal__content-noti-header">
                  {activePlan.name}
                </p>
                <p>の処方プランを選択中です。</p>
                <div
                  className="proposal__btn"
                  onClick={() => goToDetail(activePlan.id)}
                >
                  プランの詳細はこちら
                </div>
              </div>
            )}

            <ButtonCustom
              className="btn-default btn-black my--30"
              loading={false}
              disabled={!activePlan}
              onClick={handleSubmit}
              name="決済へ"
            />
          </div> */}
        </ProposalDiv>
      </div>
    </>
  )
}

export const ProposalDiv = styled.div`
  padding: 0 !important;
  margin-top: 60px;

  .proposal-content {
    padding: 1px 20px 0 20px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.13em;
    color: var(--color-rangoon-green);
    margin-top: 1.25rem;
  }

  /* submit */

  .proposal-submit {
    margin-top: 60px;
    width: 100%;
    padding: 19px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.13em;
    background: var(--color-rangoon-green);
    margin-bottom: 55px;
    margin-top: 40px;
  }

  .proposal__content {
    padding: 20px 0;

    &-badge {
      background-color: var(--color-spring-wood);
      color: var(--color-rangoon-green);
      position: relative;
      min-height: 200px;

      &-header {
        background-color: var(--color-stark-white);
        padding: 20px 20px 65px 20px;
        position: absolute;
        width: 100%;
        font-size: 13px;
      }

      &-title {
        font-weight: bold;
        font-size: 18px;
      }
    }

    &-noti {
      background-color: #f1efe7;
      border: 1px solid #cfc392;
      text-align: center;
      padding: 15px;
      margin: 20px 0;
      font-size: 15px;

      &-header {
        font-weight: bold;
        color: #998800;
      }
    }
  }

  .proposal-submit:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .proposal__btn {
    text-align: center;
    background-color: #998800;
    color: #fff;
    padding: 6px;
    margin-top: 10px;
    width: 60%;
    margin: 10px auto;
    font-weight: bold;
  }

  .reset-link {
    color: var(--color-black);
  }
`

export default ProposalContainer
