import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import LPHeader from 'src/components/layout/Header/LPHeader'
import LocalStorage from 'src/utils/LocalStorage'
import { createReservationDate } from 'src/useCase/video'
import history from 'src/libs/history'
import { PATH } from 'src/pages/video/CompleteTemporaryReserve'
import { PATH as PATH_SCHEDULE } from 'src/pages/video/Schedule'
import ButtonCustom from 'src/components/form/Button'
import ReservationInfo from 'src/components/common/ReservationInfo'
import { useAppDispatch } from 'src/store'
import { getInfo } from 'src/useCase/user'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import { MINUTE_ADD } from 'src/constants/app'

const Container: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const schedule = LocalStorage.videoSchedule
  const [loading, setLoading] = useState(false)

  const submitSchedule = async () => {
    setLoading(true)
    await createReservationDate({
      date: `${schedule.date} ${schedule.time}:00`,
      period_of_time: MINUTE_ADD,
    })
    dispatch(
      getInfo({
        with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
      }),
    )
    history.push(PATH)
    setLoading(false)
  }

  return (
    <Wrapper>
      <LPHeader />
      <div className="shampoo w-full justify-center mx-auto layout-webview mt-20">
        <HeaderUnderlined name="診察のご案内" />
        <div className="page-title fs-20 fw-500 mt-30">オンライン診察予約</div>
        <div className="shampoo__content">
          <p>下記で予約を登録します。</p>
          <ReservationInfo />
        </div>
        <ButtonCustom
          className="btn-default btn-chestnut-rose my--30"
          onClick={() => submitSchedule()}
          showIconRight
          loading={loading}
          name="予約を確定する"
        />
        <ButtonCustom
          className="btn-default btn-white my--30"
          loading={false}
          onClick={() => navigate(PATH_SCHEDULE)}
          name="戻る"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .shampoo {
    &__content {
      margin: 20px 0;
      font-size: 14px;
    }
  }
`

export default Container
